import * as React from "react";
import Styled from "styled-components";

interface ExpandedListItemProps {
  title: string;
  value: string | number | boolean;
}

const ExpandedListItem = ({ title, value }: ExpandedListItemProps) => (
  <ItemContainer>
    <ItemTitle>{title}</ItemTitle>
    <ItemValue>{value}</ItemValue>
  </ItemContainer>
);
export default ExpandedListItem;

const ItemContainer = Styled.div`
  padding: 1rem 0 1rem 0;
  border-top: 1px solid #d9d9d9;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ItemTitle = Styled.div`
  font-weight: bold;
`;

const ItemValue = Styled.div`
  opacity: 0.7;
  text-align: right;
`;
