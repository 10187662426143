import { send, uploadImage } from "../../utils/Api";
import { TenantListView, TenantDetailsView } from "./tenant.views";
import { NewTenantRequest, EditTenantRequest } from "./tenant.requests";
import { OfferType } from "../offer";

export async function create(request: NewTenantRequest): Promise<TenantListView> {
  return await send(`/tenant`, "POST", request);
}

export async function update(id: string, request: EditTenantRequest): Promise<boolean> {
  return await send(`/tenant/${id}`, "PUT", request);
}

export async function list(): Promise<TenantListView[]> {
  return await send(`/tenant/`, "GET");
}

export async function get(id: string): Promise<TenantDetailsView> {
  return await send(`/tenant/${id}`, "GET");
}

export async function listServices(): Promise<Record<OfferType, string>> {
  return await send(`/tenant/services`, "GET");
}

export async function headerImage(request: FormData | undefined) {
  return await uploadImage(`/Tenant/header/logo`, "POST", request);
}

export async function footerImage(request: FormData | undefined) {
  return await uploadImage(`/Tenant/footer/logo`, "POST", request);
}
