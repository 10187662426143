import { Layout } from "antd";
import styled from "styled-components";
import { useAuthState } from "Context/context";
import Logo from "../../../assets/img/cc-logo-white.svg";
import Sider from "../Sider";

interface MainLayoutProps {
  children: JSX.Element;
}

const MainLayout = ({ children }: MainLayoutProps) => {
  const { user } = useAuthState();
  const headerImageUrl = `/Tenant/${user.tenant}/header/logo`;

  return (
    <Layout>
      <Sider src={Logo} />
      <Layout>
        <Layout.Content>{children}</Layout.Content>
        <Layout.Footer>
          <FooterInfo>
            {`\u00A9 ${new Date().getFullYear()}`} Contract Castle by{" "}
            <Creator href="https://spiderads.io" target="_blank">
              SpiderAds
            </Creator>
          </FooterInfo>
          <FooterLogo src={headerImageUrl} />
        </Layout.Footer>
      </Layout>
    </Layout>
  );
};
export default MainLayout;

const FooterInfo = styled.span`
  color: #777;
  font-size: 12px;
`;

const Creator = styled.a`
  color: #8f00e6;

  &:hover {
    color: #fe0096;
  }
`;

const FooterLogo = styled.img`
  height: 48px;
  display: inline-block;
  right: 10px;
  bottom: 10px;
  position: fixed;
`;
