import React, { useRef } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import NewOffer from "./components/pages/NewOffer";
import EditOffer from "./components/pages/EditOffer";
import PrivateRoute from "./PrivateRoute";
import NotFound from "./components/pages/NotFound";
import { AuthProvider } from "./Context/context";
import { BrowserRouter } from "react-router-dom";
import { Auth, initialState } from "./Context/reducer";
import { decodeCurrentUser } from "./utils/tokenHelpers";
import UserList from "./components/pages/UserList";
import OfferList from "./components/pages/OfferList";
import Login from "./components/pages/Login";
import TenantSettings from "./components/pages/TenantImages";
import TenantForm from "components/forms/TenantForm";
import PasswordReset from "components/pages/Login/ResetPassword";
import PasswordResetConfirmation from "components/pages/Login/PasswordResetConfirmation";

function getInitialState(): Auth {
  const token = localStorage.getItem("token");

  const refreshToken = localStorage.getItem("refresh_token");
  if (!token || !refreshToken) return initialState;

  const user = decodeCurrentUser(token);

  return { ...initialState, user, token, refreshToken, isAuthenticated: true };
}

function App() {
  const state = useRef(getInitialState());
  return (
    <AuthProvider state={state.current}>
      <BrowserRouter>
        <Switch>
          <Route path="/login" exact={true} component={Login} />
          <Route path="/resetpassword" exact={true} component={PasswordReset} />
          <Route path="/confirm/:id" exact={false} component={PasswordResetConfirmation} />
          <PrivateRoute path="/" exact={true} component={OfferList} />
          <PrivateRoute path="/users" exact component={UserList} />
          <PrivateRoute path="/new/offer" exact component={NewOffer} />
          <PrivateRoute path="/edit/offer/:id" exact={false} component={EditOffer} />
          <PrivateRoute path="/settings/images" exact={false} component={TenantSettings} />
          <PrivateRoute path="/settings/info" exact={false} component={TenantForm} />
          <Route path="/404" component={NotFound} />
          <Redirect to="/404" />
        </Switch>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
