import { Button, Form, message } from "antd";
import { useCallback, useEffect } from "react";
import * as userApi from "../../contracts/user/user.api";
import { UserCreateRequest } from "../../contracts/user/user.api";
import { FormItem } from "components/common/Forms/FormItem";
import { Input } from "components/common/Forms/Input";
import { resolveTenant } from "../../utils/tenants";

interface CreateUserFormProps {
  id: string;
  closeModal: () => void;
  title?: string;
}

const CreateUserForm = ({ id, closeModal, title = "" }: CreateUserFormProps) => {
  const [form] = Form.useForm();

  const memoizedOnFinish = useCallback(
    async (fieldsValue: UserCreateRequest) => {
      try {
        fieldsValue = {
          ...fieldsValue,
          tenantId: resolveTenant(),
        };
        const response = await userApi.update(id, fieldsValue);
        if (response) {
          message.success("Namn redigerat!", 2);
          closeModal();
        }
      } catch (err) {
        //message.error(`${err} Något gick fel!`);
      }
    },
    [closeModal, id]
  );

  useEffect(() => {
    const fetchData = async () => {
      if (!id) return;
      const userResponse = await userApi.getUser(id);
      form.setFieldsValue({
        ...userResponse,
      });
    };
    fetchData();
  }, [id, form]);

  return (
    <Form
      name="basic"
      form={form}
      initialValues={{
        remember: true,
      }}
      layout={"vertical"}
      onFinish={memoizedOnFinish}
      preserve={false}
      className="login-form">
      <h2>{title}</h2>
      <FormItem
        label="Fullständigt namn"
        name="fullName"
        rules={[
          {
            required: true,
            message: "Fullständigt namn är kravbelagt",
          },
        ]}>
        <Input />
      </FormItem>

      <Form.Item>
        <Button type="primary" htmlType="submit">
          Redigera
        </Button>
      </Form.Item>
    </Form>
  );
};
export default CreateUserForm;
