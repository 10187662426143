import { Input as AntdInput, InputNumberProps, InputProps, InputNumber as AntdInputNumber } from "antd";
import { PasswordProps, TextAreaProps } from "antd/lib/input";
import classes from "./styles.module.css";

export function Input(props: InputProps) {
  return <AntdInput {...props} className={classes.input} />;
}

export function Password(props: PasswordProps) {
  return <AntdInput.Password {...props} className={classes.input} />;
}

export function TextArea(props: TextAreaProps) {
  return <AntdInput.TextArea {...props} className={classes.input} />;
}

export function InputNumber(props: InputNumberProps) {
  return <AntdInputNumber {...props} className={[classes.input, classes.number].join(" ")} />;
}
