/* eslint-disable no-template-curly-in-string */

const validationMessages = {
  required: "${label} är kravbelagd!",
  types: {
    number: "${label} är inte ett nummer!",
    email: "${label} är inte en giltig epost!",
  },
};

export default validationMessages;
