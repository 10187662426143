import React from "react";
import { Select } from "antd";
import { BillingPeriod, OfferType } from "../contracts/offer";

interface SelectOption {
  label: string;
  value: number | string;
}
export const renderEnumOptions = (options: SelectOption[]) => {
  return options.map((option: SelectOption, index: number | string) => (
    <Select.Option key={index} value={option.value}>
      {option.label}
    </Select.Option>
  ));
};

export const offerTypeOptions: SelectOption[] = [
  { label: "Löpande kampanj", value: OfferType.Ongoing },
  { label: "Tidsstyrd kampanj", value: OfferType.Scheduled },
  { label: "Automatisk individuell kampanj", value: OfferType.Individual },
  { label: "Engångsoffert", value: OfferType.OneTime },
];

export const contractPeriod: SelectOption[] = [
  { label: "3 månader", value: 3 },
  { label: "6 månader", value: 6 },
  { label: "12 månader", value: 12 },
  { label: "24 månader", value: 24 },
  { label: "36 månader", value: 36 },
  { label: "48 månader", value: 48 },
  { label: "60 månader", value: 60 },
];

export const evaluationPeriod: SelectOption[] = [
  { label: "Ingen", value: 0 },
  { label: "3 månader", value: 3 },
  { label: "6 månader", value: 6 },
];

export const billingPeriodOngoingOptions: SelectOption[] = [
  { label: "Två gånger per månad i förskott", value: BillingPeriod.SemiMonthly },
  { label: "Månadsvis i förskott", value: BillingPeriod.Monthly },
  { label: "Kvartalsvis i förskott", value: BillingPeriod.Quarterly },
  { label: "Halvårsvis i förskott", value: BillingPeriod.SemiAnnually },
  { label: "Årsvis i förskott", value: BillingPeriod.Annually },
];

export const billingPeriodIndividualOptions: SelectOption[] = [
  { label: "Per annons i efterskott", value: BillingPeriod.PerAdvertisement },
  { label: "Månadsvis i efterskott", value: BillingPeriod.Monthly },
];

export const paymentTerm: SelectOption[] = [
  { label: "12 dagar", value: 12 },
  { label: "17 dagar", value: 17 },
  { label: "30 dagar", value: 30 },
];
