import { Modal } from "antd";
import React from "react";
import { Button } from "components/common/Forms/Button";
import classes from "./styles.module.css";

interface MessageBoxProps {
  visible: boolean;
  onCancel?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  children?: React.ReactNode;
}

export function MessageBox({ children, ...props }: MessageBoxProps) {
  return (
    <Modal {...props} footer={null} destroyOnClose={true} className={classes.messageBox}>
      {children}
      <Button type="primary" onClick={props.onCancel}>
        Ok
      </Button>
    </Modal>
  );
}
