import React from "react";
import { ReactComponent as Logo } from "assets/img/cc-logo-black.svg";
import classes from "./styles.module.css";

interface PanelProps {
  className?: string;
  children?: React.ReactNode;
}
export function Panel(props: PanelProps) {
  const { className = "", children } = props;
  return (
    <div className={[className, classes.loginRoot].join(" ")}>
      <div className={classes.loginWrapper}>
        <div className={classes.logoWrapper}>
          <Logo className={classes.logo} />
        </div>
        <div className={classes.formContainer}>{children}</div>
      </div>
    </div>
  );
}
