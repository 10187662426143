import { Layout, Menu } from "antd";
import styled from "styled-components";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { useAuthDispatch, useAuthState } from "../../../Context/context";
import { logoutUser } from "../../../Context/actions";
import { Roles } from "../../../Context/reducer";
import { UserOutlined, DollarOutlined, LogoutOutlined, SettingOutlined } from "@ant-design/icons";

interface SiderProps {
  src: string;
  className?: string;
}

const Sider = ({ src, className = "" }: SiderProps) => {
  const history = useHistory();
  const location = useLocation();
  const { user } = useAuthState();
  const dispatch = useAuthDispatch();
  const logout = () => {
    logoutUser(dispatch);
    history.push("/login");
  };
  const { SubMenu } = Menu;
  return (
    <Layout.Sider breakpoint="lg" collapsedWidth="0" className={className}>
      <div>
        <SiderLogo src={src} alt="Logo" />
        <Menu theme="dark" mode="inline" defaultSelectedKeys={[location.pathname]}>
          {user.role !== Roles.User && (
            <>
              <Menu.Item key="/" icon={<DollarOutlined />}>
                <NavLink to="/">Offerter</NavLink>
              </Menu.Item>

              <Menu.Item key="/users" icon={<UserOutlined />}>
                <NavLink to="/users">Användare</NavLink>
              </Menu.Item>
              <SubMenu key="/settings" icon={<SettingOutlined />} title="Inställningar">
                <Menu.Item>
                  <NavLink to="/settings/images">Bilder</NavLink>
                </Menu.Item>
                <Menu.Item>
                  <NavLink to="/settings/info">Kontaktuppgifter</NavLink>
                </Menu.Item>
              </SubMenu>
            </>
          )}
        </Menu>
      </div>
      <Menu theme="dark" mode="inline" className="logoutSider">
        <Menu.Item icon={<LogoutOutlined />}>
          {user && <LogOutSpan onClick={logout}>Logga ut</LogOutSpan>}
        </Menu.Item>
      </Menu>
    </Layout.Sider>
  );
};

export default styled(Sider)`
  @media screen and (min-width: 992px) {
    .logoutSider {
      position: fixed;
      bottom: 0;
      width: 200px;
    }
  }
`;

const SiderLogo = styled.img`
  margin: 24px;
  display: block;
`;

const LogOutSpan = styled.span`
  font-size: 20px;
  &:hover {
    color: white;
  }
`;
