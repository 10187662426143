import { useCallback, useState } from "react";
import UserTable from "../../tables/UserTable";
import MainLayout from "../../layout/MainLayout";
import { Button, Modal } from "antd";
import CreateUserForm from "../../forms/CreateUserForm";

const UserList = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const memoizedShowModal = useCallback(() => {
    setIsModalVisible(true);
  }, []);

  const memoizedCloseModal = useCallback(() => {
    setIsModalVisible(false);
  }, []);

  return (
    <MainLayout>
      <div className="pageWrapper">
        <div className="headerWrapper">
          <h1>Användare</h1>
          <Button className="headerButton" onClick={memoizedShowModal}>
            Ny användare
          </Button>
        </div>
        <UserTable />
        <Modal
          footer={null}
          visible={isModalVisible}
          onCancel={memoizedCloseModal}
          destroyOnClose={true}
          style={{ top: "20%" }}>
          <CreateUserForm closeModal={memoizedCloseModal} title="Skapa användare" />
        </Modal>
      </div>
    </MainLayout>
  );
};
export default UserList;
