import { Button, Form, Popconfirm, message } from "antd";
import { useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import MainLayout from "../layout/MainLayout";
import { useAuthState } from "../../Context/context";
import { Input } from "components/common/Forms/Input";
import { FormItem } from "components/common/Forms/FormItem";
import * as tenantApi from "../../contracts/tenant/tenant.api";

const TenantSettings = () => {
  const history = useHistory();
  const [form] = Form.useForm();
  const { user } = useAuthState();

  const memoizedGetTenantData = useCallback(
    async (user) => {
      const tenantResponse = await tenantApi.get(user.tenant);
      form.setFieldsValue({
        ...tenantResponse,
      });
    },
    [form]
  );

  useEffect(() => {
    memoizedGetTenantData(user);
  }, [user, memoizedGetTenantData]);

  const memoizedOnFinish = useCallback(
    (fieldsValue) => {
      const saveTenant = async () => {
        try {
          const response = await tenantApi.update(user.tenant, fieldsValue);
          if (response) {
            message.success("Tenant redigerad!");
          }
        } catch (err) {
          //message.error(`${err} Något gick fel!`);
        }
      };
      saveTenant();
    },
    [user.tenant]
  );

  return (
    <MainLayout>
      <div className="pageWrapper">
        <div className="headerWrapper">
          <h1>Settings</h1>
          <Button className="headerButton">
            <Popconfirm
              title="Dina ändringar sparas ej. Är du säker på att du vill lämna?"
              onConfirm={() => history.push("/")}>
              Avbryt
            </Popconfirm>
          </Button>
        </div>
        <Form
          name="TenantForm"
          form={form}
          layout={"vertical"}
          onFinish={memoizedOnFinish}
          className="ant-form-content">
          <FormItem label="Kontaktperson Epost" name={["email"]} rules={[{ required: true, type: "email" }]}>
            <Input type="email" />
          </FormItem>

          <FormItem label="Kontaktperson Mobil" name={["phone"]} rules={[{ required: true, type: "string" }]}>
            <Input type="tel" />
          </FormItem>
          <FormItem label="Adress" name={["address", "street"]} rules={[{ required: true }]}>
            <Input />
          </FormItem>

          <FormItem label="Stad" name={["address", "city"]} rules={[{ required: true }]}>
            <Input />
          </FormItem>

          <FormItem
            label="Postnummer"
            name={["address", "zipCode"]}
            rules={[
              {
                required: true,
                pattern: new RegExp(/^\d{3} \d{2}$/),
                message: "Postnummer måste ha formatet: 000 00",
              },
            ]}>
            <Input />
          </FormItem>

          <FormItem
            label="Organisationsnummer"
            name={["organizationNumber"]}
            rules={[
              {
                required: true,
                pattern: new RegExp(/^\d{6}-\d{4}$/),
                message: "Organisationsnummer måste ha formatet: 000000-0000",
              },
            ]}>
            <Input />
          </FormItem>
          <FormItem className="formSubmitWrapper">
            <Button type="primary" htmlType="submit" className="formSubmitButton">
              Spara
            </Button>
          </FormItem>
        </Form>
      </div>
    </MainLayout>
  );
};
export default TenantSettings;
