import { Form } from "antd";
import { FormItem } from "components/common/Forms/FormItem";
import { Input } from "components/common/Forms/Input";
import { Button } from "components/common/Forms/Button";
import classes from "../styles.module.css";
import validationMessages from "utils/ValidationMessages";

import { ResetPasswordValues, useResetHooks } from "./hooks";
import { MessageBox } from "../MessageBox";
import { Panel } from "../Panel";

function PasswordReset() {
  const { submitPasswordReset, loading, closeMessageBox, showSuccess } = useResetHooks();

  return (
    <Panel>
      <Form<ResetPasswordValues>
        onFinish={submitPasswordReset}
        className={classes.form}
        name="resetPassword"
        layout={"vertical"}
        validateMessages={validationMessages}>
        <FormItem className="mb1 mt2">
          <h1 className={classes.header}>Återställ lösenord </h1>
        </FormItem>
        <FormItem
          name="email"
          className={classes.formItem}
          rules={[
            {
              required: true,
              message: "E-post är kravbelagt",
            },
          ]}>
          <Input disabled={loading} placeholder="* E-post" />
        </FormItem>

        <div className={classes.buttonWrapper}>
          <Button type="primary" htmlType="submit" disabled={loading} size={"large"}>
            Återställ
          </Button>
        </div>
        <MessageBox onCancel={closeMessageBox} visible={showSuccess}>
          <h3 className="mt1">Tack så mycket.</h3>
          <h4>Ett mail kommer att skickas ut till er.</h4>
          <br />
        </MessageBox>
      </Form>
    </Panel>
  );
}

export default PasswordReset;
