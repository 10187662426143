import { message } from "antd";

const getToken = () => {
  return localStorage.getItem("token");
};

const getPrivateHeaders = () => {
  return {
    "Content-Type": "application/json",
    Authorization: `Bearer ${getToken()}`,
  };
};

export const get = async (url: string) => {
  const URL = `/${url}`;
  return await fetch(URL, {
    headers: getPrivateHeaders(),
  });
};

export const downloadOffer = async (id: number) => {
  const url = `/offers/${id}/pdf`;

  const response = await fetch(url, {
    method: "POST",
    headers: getPrivateHeaders(),
  });

  const blob = await response.blob();
  const cd = response.headers.get("content-disposition") || "";
  const filename = cd.replace(/.*filename="([^"]*)".*/, "$1") || `Order ${id}.pdf`;
  const uril = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = uril;
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(uril);
};

export const deleteOffer = async (id: number) => {
  const URL = `/offers/${id}`;
  return await fetch(URL, {
    method: "DELETE",
    headers: getPrivateHeaders(),
  });
};

export async function send<T, R = unknown>(url: string, method: string, request?: R): Promise<T> {
  if (!url.startsWith("/")) url = "/" + url;

  const response = await fetch(`${url}`, {
    method: method,
    headers: getPrivateHeaders(),
    body: (request && JSON.stringify(request)) || undefined,
  });

  if (!response.ok) {
    if (response.status === 401) {
      localStorage.clear();
      window.location.href = "/login";
    } else {
      throw message.error(`Fel: ${response.statusText}`);
    }
  }

  return await response.json();
}

export async function uploadImage(url: string, method: string, request?: FormData | undefined) {
  if (!url.startsWith("/")) url = "/" + url;

  const response = await fetch(`${url}`, {
    method: method,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
    body: request,
  });

  if (!response.ok) {
    if (response.status === 401) {
      localStorage.clear();
      window.location.href = "/login";
    } else {
      throw message.error(`Fel: ${response.statusText}`);
    }
  }

  return await response.json();
}
