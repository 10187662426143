import React, { useCallback } from "react";
import { Form } from "antd";
import { Link, useHistory } from "react-router-dom";
import { loginUser } from "../../../Context/actions";
import { useAuthDispatch, useAuthState } from "../../../Context/context";
import { resolveTenant } from "utils/tenants";
import classes from "./styles.module.css";
import { FormItem } from "components/common/Forms/FormItem";
import { Input, Password } from "components/common/Forms/Input";
import { ReactComponent as Logo } from "assets/img/cc-logo-black.svg";
import { Button } from "components/common/Forms/Button";

interface LoginModel {
  email: string;
  password: string;
}

const Login = () => {
  const history = useHistory();
  const dispatch = useAuthDispatch();
  const { loading, errorMessage } = useAuthState();
  const handleLogin = useCallback(
    (values: LoginModel) => {
      const loginAsync = async () => {
        const { email, password } = values;
        try {
          const response = await loginUser(dispatch, {
            tenant: resolveTenant(),
            email: email,
            password: password,
          }); //loginUser action makes the request and handles all the neccessary state changes
          if (!response) return;
          history.push("/"); //navigate to dashboard on success
        } catch (error) {
          console.error(error);
        }
      };
      loginAsync();
    },
    [dispatch, history]
  );

  return (
    <div className={classes.loginRoot}>
      <div className={classes.loginWrapper}>
        <div className={classes.logoWrapper}>
          <Logo className={classes.logo} />
        </div>
        <div className={classes.formContainer}>
          <Form<LoginModel>
            name="basic"
            initialValues={{
              remember: true,
            }}
            layout={"vertical"}
            onFinish={handleLogin}
            className={classes.form}>
            <FormItem className="mb1 mt2">
              <h1 className={classes.header}>Logga in</h1>
            </FormItem>

            <FormItem
              name="email"
              className={classes.formItem}
              rules={[
                {
                  required: true,
                  message: "E-post är kravbelagt",
                },
              ]}>
              <Input disabled={loading} placeholder="* E-post" />
            </FormItem>

            <FormItem
              name="password"
              rules={[
                {
                  required: true,
                  message: "Lösenord är kravbelagt",
                },
              ]}>
              <Password disabled={loading} placeholder="* Lösenord" />
            </FormItem>
            {errorMessage && (
              <FormItem>
                <p>{errorMessage}</p>
              </FormItem>
            )}

            <div className={classes.buttonWrapper}>
              <Button type="primary" htmlType="submit" disabled={loading} size={"large"}>
                Logga in
              </Button>
              <Link to="/resetpassword" className={classes.link}>
                Glömt ditt lösenord?
              </Link>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Login;
