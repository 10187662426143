import { useState, useEffect, useCallback, useMemo } from "react";
import { ColumnsType } from "antd/lib/table";
import { Button, Dropdown, message, Table } from "antd";
import { isMobileOnly } from "react-device-detect";
import Styled from "styled-components";
import moment from "moment";
import { OfferListView } from "../../../contracts/offer.views";
import * as offersApi from "../../../contracts/offer.api";
import { Status } from "../../../contracts/offer";
import DropdownActions, { Action } from "../../common/DropdownActions";
import { useHistory } from "react-router";
import { DeleteOutlined, CheckOutlined, DownloadOutlined, EditFilled } from "@ant-design/icons";
import * as Api from "../../../utils/Api";
import ExpandedListItem from "../../common/ExpandedListItem";

const OfferTable = () => {
  const [errorTitle, setErrorTitle] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [loading, setLoading] = useState(true);
  const [offers, setOffers] = useState<OfferListView[]>([]);
  const history = useHistory();

  const refreshToken = localStorage.getItem("refresh_token");
  const token = localStorage.getItem("token");

  const errStatus = (err: { message: string }) => {
    setErrorTitle("Felmeddelande ");
    setErrorMessage(err.message);
  };

  const downloadOffer = useCallback((id: number) => {
    const startDownload = async () => {
      try {
        setLoading(true);
        await Api.downloadOffer(id);
        setLoading(false);
      } catch (err) {
        message.error(err);
        setLoading(false);
      }
    };

    startDownload();
  }, []);

  const deleteOffer = useCallback(
    (id: number) => {
      Api.deleteOffer(id);
      setOffers(offers.filter((item) => item.id !== id));
    },
    [offers]
  );
  const memoizedGetOfferData = useCallback(() => {
    const fetchOffers = async () => {
      try {
        const offers = await offersApi.list();
        setOffers(offers);
        setLoading(false);
      } catch (err) {
        errStatus(err);
      }
    };
    fetchOffers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshToken, token]);

  const confirmOffer = useCallback(
    (id: number) => {
      const fetchOffersAsync = async () => {
        await offersApi.confirm(id);
        memoizedGetOfferData();
      };
      fetchOffersAsync();
    },
    [memoizedGetOfferData]
  );

  const offerActions = useMemo<Action[]>(
    () => [
      { text: "Godkänn", icon: <CheckOutlined />, onClick: (id: number) => confirmOffer(id) },
      {
        text: "Redigera",
        icon: <EditFilled />,
        onClick: (id: number) => history.push(`/edit/offer/${id}`),
      },
      { text: "Hämta avtal", icon: <DownloadOutlined />, onClick: downloadOffer },
      { text: "Radera offert", icon: <DeleteOutlined />, onClick: (id: number) => deleteOffer(id) },
    ],
    [confirmOffer, deleteOffer, downloadOffer, history]
  );

  const columns: ColumnsType<OfferListView> = useMemo(
    () => [
      {
        title: "Ordernummer",
        dataIndex: "id",
        key: "id",
        responsive: ["lg"],
        sorter: (a: OfferListView, b: OfferListView) => a.id - b.id,
      },
      {
        title: "Klientnamn",
        dataIndex: "clientName",
        key: "clientName",
        sorter: (a: { clientName: string }, b: { clientName: string }) => {
          return a.clientName.localeCompare(b.clientName);
        },
      },
      {
        title: "Säljare",
        dataIndex: "salesPerson",
        key: "salesPerson",
        responsive: ["lg"],
        onFilter: (value: any, offer: OfferListView) => offer.salesPerson.indexOf(value) === 0,
      },
      {
        title: "Avtalsstart",
        dataIndex: "startDate",
        key: "startDate",
        responsive: ["md"],
        sorter: (a: OfferListView, b: OfferListView) =>
          moment(a.startDate).unix() - moment(b.startDate).unix(),
        render: (_text: string, offer: OfferListView) => (
          <span>{offer.startDate ? moment(offer.startDate).format("YYYY-MM-DD") : ""}</span>
        ),
      },
      {
        title: "Avtalsslut",
        dataIndex: "endDate",
        key: "endDate",
        responsive: ["lg"],
        sorter: (a: OfferListView, b: OfferListView) => moment(a.endDate).unix() - moment(b.endDate).unix(),
        render: (_text: string, offer: OfferListView) => (
          <span>{offer.endDate ? moment(offer.endDate).format("YYYY-MM-DD") : ""}</span>
        ),
      },
      {
        title: "Skapad",
        dataIndex: "created",
        key: "created",
        responsive: ["lg"],
        sorter: (a: OfferListView, b: OfferListView) => moment(a.created).unix() - moment(b.created).unix(),
        render: (_text: string, offer: OfferListView) => (
          <span>{moment(offer.created).format("YYYY-MM-DD HH:MM")}</span>
        ),
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        responsive: ["lg"],
        filters: [
          { text: "Ej godkända", value: Status.Waiting },
          { text: "Godkända", value: Status.Won },
        ],
        onFilter: (value: any, offer: OfferListView) => offer.status === value,
      },
      {
        title: "Funktioner",
        render: (offer: OfferListView) => (
          <Dropdown.Button
            trigger={["click"]}
            overlay={DropdownActions(offer.id, offerActions)}></Dropdown.Button>
        ),
        responsive: ["md"],
      },
    ],
    [offerActions]
  );

  useEffect(() => {
    memoizedGetOfferData();
  }, [memoizedGetOfferData, refreshToken, token]);

  return (
    <>
      {errorTitle && (
        <ErrorContainer>
          <ErrorWrapper>
            <ErrorTitle>{errorTitle}</ErrorTitle>
            <ErrorMessage>{errorMessage}</ErrorMessage>
          </ErrorWrapper>
        </ErrorContainer>
      )}
      <Table
        loading={loading}
        columns={columns}
        dataSource={offers}
        rowKey={(offer) => offer.id}
        expandRowByClick
        expandable={{
          expandedRowRender: (offer: OfferListView) => (
            <>
              <ExpandedListItem title="Offertnummer" value={offer.id} />
              <ExpandedListItem title="Avtalsstart" value={offer.startDate || ""} />
              <ExpandedListItem title="Avtalsslut" value={(offer.endDate && offer.endDate) || "Tillsvidare"} />
              <ExpandedListItem title="Säljare" value={offer.salesPerson} />
              <ExpandedListItem title="Skapad" value={offer.created} />
              <ExpandedListItem title="Status" value={offer.status} />
              <Dropdown
                overlay={DropdownActions(offer.id, offerActions)}
                trigger={["click"]}
                placement="topCenter"
                className="mobileTriggerButton">
                <Button>Funktioner</Button>
              </Dropdown>
            </>
          ),
          rowExpandable: () => isMobileOnly,
        }}
      />
    </>
  );
};

const ErrorContainer = Styled.div`
  display:grid;
  z-index:10;
  top:0;
  left:0;
  place-items: center;
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: rgba(148, 147, 147, 0.5);
`;

const ErrorWrapper = Styled.div`
  width: auto;
  padding: 5%;
  background-color: #fff;
`;

const ErrorTitle = Styled.h1`
  text-align: center;
  font-weight: bold;
  font-size: 2rem;
  margin: 0;
`;
const ErrorMessage = Styled.h2`
  text-align: center;
  font-weight: bold;
`;

export default OfferTable;
