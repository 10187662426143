import { Button, Form, message, Select as AntSelect } from "antd";
import { useCallback } from "react";
import * as userApi from "../../contracts/user/user.api";
import { UserCreateRequest } from "../../contracts/user/user.api";
import { FormItem } from "components/common/Forms/FormItem";
import { Input, Password } from "components/common/Forms/Input";
import { Select } from "components/common/Forms/Select";
import { checkPassword } from "utils/passwords";
import { resolveTenant } from "../../utils/tenants";

interface CreateUserFormProps {
  closeModal: () => void;
  title?: string;
}

const CreateUserForm = ({ closeModal, title = "" }: CreateUserFormProps) => {
  const { Option } = AntSelect;
  const [form] = Form.useForm();

  const memoizedOnFinish = useCallback(
    async (fieldsValue: UserCreateRequest) => {
      try {
        fieldsValue = {
          ...fieldsValue,
          tenantId: resolveTenant(),
        };
        const response = await userApi.create(fieldsValue);
        if (response) {
          message.success("Användare skapad", 2);
          closeModal();
        }
      } catch (err) {
        //message.error(`${err} Något gick fel!`);
      }
    },
    [closeModal]
  );

  return (
    <Form
      name="basic"
      form={form}
      initialValues={{
        remember: true,
      }}
      layout={"vertical"}
      onFinish={memoizedOnFinish}
      preserve={false}>
      <h2>{title}</h2>
      <FormItem
        label="Fullständigt namn"
        name="fullName"
        rules={[
          {
            required: true,
            message: "Fullständigt namn är kravbelagt",
          },
        ]}>
        <Input />
      </FormItem>

      <FormItem
        label="E-post"
        name="email"
        rules={[
          {
            required: true,
            type: "email",
            message: "E-post är kravbelagt",
          },
        ]}>
        <Input />
      </FormItem>

      <FormItem
        label="Roll"
        name="role"
        rules={[
          {
            required: true,
            message: "Roll är kravbelagt",
          },
        ]}>
        <Select>
          <Option value="User">Användare</Option>
          <Option value="Admin">Admin</Option>
        </Select>
      </FormItem>

      <FormItem
        label="Lösenord"
        name="password"
        rules={[
          {
            validator: async (_, value) => {
              const result = await checkPassword(value);

              if (result?.length) {
                throw result;
              }
            },
          },
        ]}>
        <Password />
      </FormItem>

      <FormItem>
        <Button type="primary" htmlType="submit">
          Skapa
        </Button>
      </FormItem>
    </Form>
  );
};
export default CreateUserForm;
