import { Button, Form, Popconfirm, Upload, message } from "antd";
import { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import MainLayout from "../../layout/MainLayout";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { useAuthState } from "Context/context";
import * as tenantApi from "../../../contracts/tenant/tenant.api";

const TenantSettings = () => {
  const history = useHistory();
  const [form] = Form.useForm();
  const { user } = useAuthState();
  const [loading, setLoading] = useState(false);
  const [headerImageUrl, setHeaderImageUrl] = useState(`/Tenant/${user.tenant}/header/logo`);
  const [footerImageUrl, setFooterImageUrl] = useState(`/Tenant/${user.tenant}/footer/logo`);

  const uploadImageCheck = (file: File) => {
    if (file) {
      if (!file.type.includes("image")) {
        message.error("You can only upload JPG/PNG file!");
        setLoading(false);
      } else {
        if (file.size > 50000000) {
          message.error("Image must smaller than 50MB!");
          setLoading(false);
        } else {
          const config = new FormData();
          config.append("ImageFile", file);
          return config;
        }
      }
    }
  };

  const memoizeduploadHeaderImage = useCallback((event) => {
    const uploadHeaderImage = async () => {
      setLoading(true);
      try {
        const file = event.file;
        const request = uploadImageCheck(file);
        const response = await tenantApi.headerImage(request);
        if (response) {
          message.success("Uppladdning av bild lyckad!");
          const imagePreview = URL.createObjectURL(file);
          setHeaderImageUrl(imagePreview);
        }
        setLoading(false);
      } catch (err) {
        //errStatus(err);
      }
      setLoading(false);
    };
    uploadHeaderImage();
  }, []);

  const memoizeduploadFooterImage = useCallback((event) => {
    const uploadFooterImage = async () => {
      setLoading(true);
      try {
        const file = event.file;
        const request = uploadImageCheck(file);
        const response = await tenantApi.footerImage(request);
        if (response) {
          message.success("Uppladdning av bild lyckad!");
          const imagePreview = URL.createObjectURL(file);
          setFooterImageUrl(imagePreview);
        }
        setLoading(false);
      } catch (err) {
        //errStatus(err);
      }
      setLoading(false);
    };
    uploadFooterImage();
  }, []);

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <MainLayout>
      <div className="pageWrapper">
        <div className="headerWrapper">
          <h1>Settings</h1>
        </div>
        <Form name="TenantImages" form={form} layout={"vertical"} className="ant-form-content">
          <Form.Item
            label="Ladda upp header bild"
            name="headerImg"
            rules={[{ required: true }]}
            style={{ placeItems: "center" }}>
            <Upload
              listType="picture-card"
              showUploadList={false}
              multiple={false}
              beforeUpload={() => false}
              onChange={memoizeduploadHeaderImage}>
              {headerImageUrl ? <img src={headerImageUrl} style={{ width: "100%" }} /> : uploadButton}
            </Upload>
          </Form.Item>

          <Form.Item
            label="Ladda upp Footer bild"
            name="footerImg"
            rules={[{ required: true }]}
            style={{ placeItems: "center" }}>
            <Upload
              listType="picture-card"
              showUploadList={false}
              multiple={false}
              beforeUpload={() => false}
              onChange={memoizeduploadFooterImage}>
              {footerImageUrl ? <img src={footerImageUrl} style={{ width: "100%" }} /> : uploadButton}
            </Upload>
          </Form.Item>
        </Form>
      </div>
    </MainLayout>
  );
};
export default TenantSettings;
