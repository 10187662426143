import React, { useEffect } from "react";
import { Redirect, Route, RouteComponentProps, RouteProps } from "react-router-dom";
import { refreshTokenRequest } from "./Context/actions";
import { useAuthDispatch, useAuthState } from "./Context/context";
import { decodeCurrentUser } from "./utils/tokenHelpers";
import { dateInPast } from "./utils/dateHelpers";
import LoadingScreen from "./components/common/LoadingScreen";

interface PrivateRouteProps extends RouteProps {
  component:
    | React.ComponentType<RouteComponentProps<Record<string, string | undefined>>>
    | React.ComponentType<unknown>;
}

const expired = (token: string) => {
  if (!token) return false;
  const user = decodeCurrentUser(token);
  const expired = dateInPast((+user.expiresUnix - 1) * 1000);
  return expired;
};

const useRefreshedState = () => {
  const state = useAuthState();
  const dispatch = useAuthDispatch();

  const tokenExpired = expired(state.token);

  useEffect(() => {
    const refresh = async () => {
      try {
        if (!tokenExpired) return;

        dispatch({ type: "REFRESH_TOKEN_REQUEST" });
        const newToken = (await refreshTokenRequest(state.token, state.refreshToken)) ?? {};
        if (!newToken.token || !newToken.refreshToken) {
          localStorage.clear();
          dispatch({ type: "LOGOUT" });
        } else {
          localStorage.setItem("token", newToken.token);
          localStorage.setItem("refresh_token", newToken.refreshToken);

          dispatch({
            type: "REFRESH_TOKEN_SUCCESS",
            payload: {
              user: decodeCurrentUser(newToken.token),
              token: newToken.token,
              refreshToken: newToken.refreshToken,
            },
          });
        }
      } catch (error) {
        localStorage.clear();
        dispatch({ type: "LOGOUT" });
      }
    };

    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, tokenExpired]);

  return { state };
};

const PrivateRoute = ({ component, path, exact }: PrivateRouteProps) => {
  const { state } = useRefreshedState();
  const { loading, isAuthenticated, token } = state;

  if (loading || (!!state.token && expired(token))) return <LoadingScreen />;

  const Component = component;

  return (
    <Route
      path={path}
      exact={exact}
      render={(props) => (isAuthenticated ? <Component {...props} /> : <Redirect to="/login" />)}
    />
  );
};

export default PrivateRoute;
