const DEFAULT_TENANT = process.env.REACT_APP_DEFAULT_TENANT ?? "spiderads";

export const resolveTenant = (): string => {
  const { hostname } = location;
  if (hostname === "localhost") return DEFAULT_TENANT;

  const tenantMatch = hostname.match(/^[^.]+/) ?? [DEFAULT_TENANT];

  return tenantMatch[0];
};
