export interface OfferBase {
  agreement: Agreement;
  client: Client;
  contactPerson: ContactPerson;
}
export interface Agreement {
  offerType: OfferType;
  paymentTerm: number;
  billingPeriod: BillingPeriod;
  budget: number;
  costCenter: string;
  contractPeriod?: number;
  initialContractPeriod?: number;
  evaluationPeriod?: number;
  startCost?: number;
  comment?: string;
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
  cpm?: number;
  services: string[];
  exclusiveProviderClause: boolean;
}

export interface Client {
  address: Address;
  name: string;
  organizationNumber: string;
  phone: string;
  website: string;
}

export interface Address {
  city: string;
  street: string;
  zipCode: string;
}

export interface ContactPerson {
  email: string;
  name: string;
  phone: string;
}

export enum OfferType {
  Ongoing = "Ongoing",
  Scheduled = "Scheduled",
  Individual = "Individual",
  OneTime = "OneTime",
}

export enum BillingPeriod {
  None = 0,
  PerAdvertisement = 1,
  SemiMonthly = 2,
  Monthly = 3,
  Quarterly = 4,
  SemiAnnually = 5,
  Annually = 6,
}

export enum Status {
  Waiting = 0,
  Won = 1,
  Lost = 2,
  Archived = 3,
}
