import { Roles } from "../../Context/reducer";
import { send } from "../../utils/Api";
import { resolveTenant } from "utils/tenants";

export interface UserBase {
  fullName: string;
  email: string;
  role: Roles;
}

export interface UserListView extends UserBase {
  id: string;
}

export interface UserCreateRequest extends UserListView {
  password: string;
  tenantId: string;
}

interface ApiResponse {
  succeded: boolean;
  errors: string[];
}

export async function create(request: UserCreateRequest): Promise<UserListView> {
  return await send(`/user/register`, "POST", request);
}

export async function update(id: string, request: UserBase): Promise<ApiResponse> {
  return await send(`/user/${id}`, "PUT", request);
}

export async function list(): Promise<UserListView[]> {
  return await send(`/user/`, "GET");
}

export async function getUser(id: string): Promise<UserListView> {
  return await send(`/user/${id}`, "GET");
}

export async function deleteUser(id: string): Promise<boolean> {
  return await send(`/user/${id}`, "DELETE");
}

export async function resetPassword(email: string): Promise<boolean> {
  const tenantId = resolveTenant();
  return await send(`/password-reset`, "POST", { tenantId, email });
}

export async function confirmPasswordReset(token: string, password: string): Promise<boolean> {
  return await send(`/confirm-password-reset`, "POST", { token, password });
}
