import React from "react";
import { Menu } from "antd";

export interface Action {
  icon: JSX.Element;
  text: string;
  onClick: any;
}

// offer.id is a number and user.id is a guid
const DropdownActions = (id: number | string, actions: Action[]) => {
  return (
    <Menu>
      {actions.map((action: Action, index: number) => (
        <Menu.Item key={index} icon={action.icon} onClick={() => action.onClick(id)}>
          {action.text}
        </Menu.Item>
      ))}
    </Menu>
  );
};

export default DropdownActions;
