import moment from "moment";
import { send } from "../utils/Api";
import { BillingPeriod, Client, ContactPerson, OfferBase, OfferType } from "./offer";
import { OfferListView } from "./offer.views";

export async function create(request: OfferBase): Promise<OfferListView> {
  return await send(`/offers`, "POST", request);
}

export async function update(id: number, request: OfferBase): Promise<OfferListView> {
  return await send(`/offers/${id}`, "PUT", request);
}

export async function confirm(id: number): Promise<void> {
  return await send(`/offers/${id}/confirm`, "POST");
}

export async function list(): Promise<OfferListView[]> {
  return await send(`/offers/`, "GET");
}

interface OfferResponse {
  agreement: {
    offerType: OfferType;
    paymentTerm: number;
    billingPeriod: BillingPeriod;
    budget: number;
    costCenter: string;
    contractPeriod?: number;
    initialContractPeriod?: number;
    evaluationPeriod?: number;
    startCost?: number;
    comment?: string;
    cpm?: number;
    startDate: string | null;
    endDate: string | null;
    services: string[];
    exclusiveProviderClause: boolean;
  };
  client: Client;
  contactPerson: ContactPerson;
}

export async function get(id: number): Promise<OfferBase> {
  const { agreement, ...rest }: OfferResponse = await send(`/offers/${id}`, "GET");
  const mapped: OfferBase = {
    ...rest,
    agreement: {
      ...agreement,
      startDate: agreement.startDate ? moment(agreement.startDate) : null,
      endDate: agreement.startDate ? moment(agreement.endDate) : null,
    },
  };
  return mapped;
}
