import React, { useReducer } from "react";
import { AuthReducer, initialState, Auth, AuthActionType } from "./reducer";

const AuthStateContext = React.createContext<Auth>(null as any);
const AuthDispatchContext = React.createContext<React.Dispatch<AuthActionType>>(null as any);

export function useAuthState() {
  const context = React.useContext(AuthStateContext);
  if (context === undefined) {
    throw new Error("useAuthState must be used within a AuthProvider");
  }

  return context;
}

export function useAuthDispatch() {
  const context = React.useContext(AuthDispatchContext);
  if (context === undefined) {
    throw new Error("useAuthDispatch must be used within a AuthProvider");
  }

  return context;
}

interface AuthProviderProps {
  children: React.ReactElement;
  state?: Auth;
}

export const AuthProvider = (props: AuthProviderProps) => {
  const { children, state = initialState } = props;
  const [auth, dispatch] = useReducer(AuthReducer, state);
  return (
    <AuthStateContext.Provider value={auth}>
      <AuthDispatchContext.Provider value={dispatch}>{children}</AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  );
};
