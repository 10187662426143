import styled from "styled-components";
import Logo from "../../../assets/img/cc-logo-white.svg";

const NotFound = () => (
  <NotFoundContainer>
    <div className="boxWrapper">
      <div className="sidebar">
        <img src={Logo} className="logo" alt="Page Not Found" />
      </div>

      <div className="contentWrapper">
        <h2>404 sidan finns inte</h2>
        <a href="/">Tillbaka till förstasidan</a>
      </div>
    </div>
  </NotFoundContainer>
);
export default NotFound;

const NotFoundContainer = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;

  .boxWrapper {
    background: #fff;
    border-radius: 3px;
    display: flex;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
  }

  .sidebar {
    background-color: #292f36;
    height: auto;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    width: 240px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3em 0;
    justify-content: center;
  }

  .logo {
    max-width: 200px;
    max-height: 130px;
  }

  .contentWrapper {
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 190px;
    width: 500px;
    justify-content: center;
  }
`;
