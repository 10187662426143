import React from "react";
import { useParams } from "react-router-dom";
import OfferForm from "../../forms/OfferForm";

const EditOffer = () => {
  const { id }: any = useParams();
  return <OfferForm id={id} title="Redigera Offert" />;
};

export default EditOffer;
