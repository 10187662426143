import { pwnedPassword } from "hibp";

const addIf = <T extends unknown>(check: unknown, result: T): T[] => (check ? [result] : []);

async function isPawned(password: string, minLength: number): Promise<boolean> {
  if (password.length < minLength) return false;
  const pwnedOccurrences = await pwnedPassword(password);
  return pwnedOccurrences > 0;
}

export async function checkPassword(password: string) {
  const notGood = await isPawned(password, 6);
  return [
    ...addIf(notGood, "Lösenordet är för vanligt förekommande"),
    ...addIf(password.length < 6, "Lösenordet måste vara minst sex tecken långt"),
    ...addIf(!password.match(/[^0-9a-zA-ZåäöÅÄÖ]+/), "Lösenordet måste innehålla minst ett specialtecken"),
    ...addIf(!password.match(/[0-9]+/), "Lösenordet måste innehålla minst en siffra"),
    ...addIf(!password.match(/[a-zåäö]+/), "Lösenordet måset innehålla minst en gemen"),
    ...addIf(!password.match(/[A-ZÅÄÖ]+/), "Lösenordet måste innehålla minst en versal"),
  ];
}
