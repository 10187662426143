import jwtDecode from "jwt-decode";
import { User } from "../Context/reducer";

export const TokenUserPayloadKeys = {
  IDENTITY_CLAIM_ID_KEY: "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier",
  IDENTITY_CLAIM_EMAIL_KEY: "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress",
  IDENTITY_CLAIM_NAME_KEY: "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name",
  IDENTITY_CLAIM_ROLE_KEY: "http://schemas.microsoft.com/ws/2008/06/identity/claims/role",
};

export function decodeCurrentUser(token: string): User {
  const decodedToken = jwtDecode<any>(token);
  const roles = decodedToken[TokenUserPayloadKeys.IDENTITY_CLAIM_ROLE_KEY];

  return {
    id: decodedToken[TokenUserPayloadKeys.IDENTITY_CLAIM_ID_KEY],
    email: decodedToken[TokenUserPayloadKeys.IDENTITY_CLAIM_EMAIL_KEY],
    name: decodedToken[TokenUserPayloadKeys.IDENTITY_CLAIM_NAME_KEY],
    //Roles are in descending order from highest to lowest authority
    role: Array.isArray(roles) ? roles[0] : roles,
    expiresUnix: Number(decodedToken.exp),
    tenant: decodedToken.tenant,
  };
}
