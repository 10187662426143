import { useState, useEffect, useCallback } from "react";
import { ColumnsType } from "antd/lib/table";
import { Button, Dropdown, Modal, Table } from "antd";
import { isMobileOnly } from "react-device-detect";
import { Roles } from "../../../Context/reducer";
import DropdownActions, { Action } from "../../common/DropdownActions";
import { UserDeleteOutlined } from "@ant-design/icons";
import ExpandedListItem from "../../common/ExpandedListItem";
import Styled from "styled-components";
import * as userApi from "../../../contracts/user/user.api";
import { UserListView } from "../../../contracts/user/user.api";
import EditUserForm from "../../forms/EditUserForm";

const UserTable = () => {
  const [errorTitle, setErrorTitle] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [currentUserId, setCurrentUserId] = useState<string>("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState<UserListView[]>([]);
  const refreshToken = localStorage.getItem("refresh_token");
  const token = localStorage.getItem("refresh_token");

  const memoizedShowModal = useCallback((id: string) => {
    setIsModalVisible(true);
    setCurrentUserId(id);
  }, []);

  const errStatus = (err: { message: string }) => {
    setErrorTitle("Felmeddelande ");
    setErrorMessage(err.message);
  };

  const memoizedGetUsersData = useCallback(() => {
    const fetchUsers = async () => {
      try {
        const users = await userApi.list();
        setUsers(users);
        setLoading(false);
      } catch (err) {
        errStatus(err);
      }
    };
    fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshToken, token]);

  const memoizedCloseModal = useCallback(() => {
    setIsModalVisible(false);
    memoizedGetUsersData();
  }, [memoizedGetUsersData]);

  useEffect(() => {
    memoizedGetUsersData();
  }, [memoizedGetUsersData, refreshToken, token]);

  const handleDelete = async (id: string) => {
    if (confirm("Är du säker på du vill radera?")) {
      try {
        const deleteRequest = await userApi.deleteUser(id);
        if (deleteRequest) {
          memoizedGetUsersData();
        } else {
          setErrorTitle("Fel");
          setErrorMessage("Det gick inte ta bort användare");
        }
      } catch (err) {
        errStatus(err);
      }
    }
  };
  const userActions: Action[] = [
    { text: "Radera användare", icon: <UserDeleteOutlined />, onClick: (id: string) => handleDelete(id) },
    {
      text: "Redigera",
      icon: <UserDeleteOutlined />,
      onClick: memoizedShowModal,
    },
  ];

  const columns: ColumnsType<UserListView> = [
    {
      title: "Namn",
      dataIndex: "fullName",
      key: "fullName",
      responsive: ["lg"],
      sorter: (a: { fullName: string }, b: { fullName: string }) => {
        return a.fullName.localeCompare(b.fullName);
      },
    },
    {
      title: "E-post",
      dataIndex: "email",
      key: "email",
      sorter: (a: { email: string }, b: { email: string }) => {
        return a.email.localeCompare(b.email);
      },
    },
    {
      title: "Roll",
      dataIndex: "role",
      key: "role",
      responsive: ["lg"],
      filters: [
        { text: "Super Admin", value: Roles.SuperAdmin },
        { text: "Admin", value: Roles.Admin },
        { text: "Användare", value: Roles.User },
      ],
      onFilter: (value: any, user: UserListView) => user.role === value,
    },

    {
      title: "Funktioner",
      render: (user: UserListView) => (
        <Dropdown.Button trigger={["click"]} overlay={DropdownActions(user.id, userActions)}></Dropdown.Button>
      ),
      responsive: ["md"],
    },
  ];
  return (
    <>
      {errorTitle && (
        <ErrorContainer>
          <ErrorWrapper>
            <ErrorTitle>{errorTitle}</ErrorTitle>
            <ErrorMessage>{errorMessage}</ErrorMessage>
          </ErrorWrapper>
        </ErrorContainer>
      )}
      <Table
        loading={loading}
        columns={columns}
        dataSource={users}
        rowKey={(offer) => offer.id}
        expandRowByClick
        expandable={{
          expandedRowRender: (user: UserListView) => (
            <>
              <ExpandedListItem title="Namn" value={user.fullName} />
              <ExpandedListItem title="E-post" value={user.email} />
              <ExpandedListItem title="Roll" value={user.role} />
              <Dropdown
                overlay={DropdownActions(user.id, userActions)}
                trigger={["click"]}
                placement="topCenter"
                className="mobileTriggerButton">
                <Button>Funktioner</Button>
              </Dropdown>
            </>
          ),
          rowExpandable: () => isMobileOnly,
        }}
      />
      <Modal footer={null} visible={isModalVisible} onCancel={memoizedCloseModal} destroyOnClose={true}>
        <EditUserForm id={currentUserId} closeModal={memoizedCloseModal} title="Redigera användare" />
      </Modal>
    </>
  );
};

export default UserTable;

const ErrorContainer = Styled.div`
  display:grid;
  z-index:10;
  top:0;
  left:0;
  place-items: center;
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: rgba(148, 147, 147, 0.5);
`;

const ErrorWrapper = Styled.div`
  width: auto;
  padding: 5%;
  background-color: #fff;
`;

const ErrorTitle = Styled.h1`
  text-align: center;
  font-weight: bold;
  font-size: 2rem;
  margin: 0;
`;
const ErrorMessage = Styled.h2`
  text-align: center;
  font-weight: bold;
`;
