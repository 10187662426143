import { useCallback, useState } from "react";
import { useHistory } from "react-router";
import { resetPassword } from "contracts/user/user.api";

export interface ResetPasswordValues {
  email: string;
}

export function useResetHooks() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const closeMessageBox = useCallback(() => {
    setLoading(false);
    setShowSuccess(false);
    history.replace("/");
  }, [history]);

  const submitPasswordReset = useCallback((values: ResetPasswordValues) => {
    const resetPasswordAsync = async () => {
      try {
        await resetPassword(values.email);
        setShowSuccess(true);
      } catch (e) {
        setLoading(false);
        console.error(e);
      }
    };
    setLoading(true);
    resetPasswordAsync();
  }, []);
  return { loading, showSuccess, closeMessageBox, submitPasswordReset };
}
