export enum Roles {
  SuperAdmin = "SuperAdmin",
  Admin = "Admin",
  User = "User",
}

export interface User {
  id: string;
  email: string;
  name: string;
  role: Roles;
  expiresUnix: number;
  tenant: string;
}

export interface Auth {
  user: User;
  loading: boolean;
  errorMessage: string;
  token: string;
  refreshToken: string;
  isAuthenticated: boolean;
}

export const initialState: Auth = {
  user: {} as User,
  loading: false,
  errorMessage: "",
  token: "",
  refreshToken: "",
  isAuthenticated: false,
};

export type AuthActionType =
  | { type: "REQUEST_LOGIN" }
  | {
      type: "LOGIN_SUCCESS";
      payload: { user: User; token: string; refreshToken: string };
    }
  | { type: "LOGIN_ERROR"; error: string }
  | { type: "REFRESH_TOKEN_REQUEST" }
  | {
      type: "REFRESH_TOKEN_SUCCESS";
      payload: { user: User; token: string; refreshToken: string };
    }
  | { type: "LOGOUT" };

export const AuthReducer = (state: Auth, action: AuthActionType): Auth => {
  switch (action.type) {
    case "REQUEST_LOGIN":
      return {
        ...state,
        loading: true,
      };
    case "LOGIN_SUCCESS":
      return {
        ...state,
        user: action.payload.user,
        token: action.payload.token,
        refreshToken: action.payload.refreshToken,
        isAuthenticated: true,
        loading: false,
      };
    case "LOGIN_ERROR":
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        errorMessage: action.error,
      };
    case "REFRESH_TOKEN_REQUEST":
      return {
        ...state,
        loading: true,
        isAuthenticated: false,
      };
    case "REFRESH_TOKEN_SUCCESS":
      return {
        ...state,
        token: action.payload.token,
        refreshToken: action.payload.refreshToken,
        user: action.payload.user,
        isAuthenticated: true,
        loading: false,
      };
    case "LOGOUT":
      return {
        ...state,
        isAuthenticated: false,
      };

    default:
      throw new Error(`Unhandled action type: ${(action as any).type}`);
  }
};
