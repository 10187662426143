import { useCallback, useState } from "react";
import { confirmPasswordReset } from "contracts/user/user.api";
import { useHistory } from "react-router";

export interface ConfirmPasswordResetValues {
  password: string;
}

/*
  "Passwords must be at least 6 characters.",
  "Passwords must have at least one non alphanumeric character.",
  "Passwords must have at least one digit ('0'-'9').",
  "Passwords must have at least one lowercase ('a'-'z').",
  "Passwords must have at least one uppercase ('A'-'Z').",
  "The password you chose has appeared in a data breach."
  */

export function useHooks(token: string) {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const closeMessageBox = useCallback(() => {
    setLoading(false);
    setShowSuccess(false);
    history.replace("/");
  }, [history]);

  const submitConfirmation = useCallback(
    (values: ConfirmPasswordResetValues) => {
      const resetPasswordAsync = async () => {
        try {
          await confirmPasswordReset(token, values.password);
          setShowSuccess(true);
        } catch (e) {
          console.error(e);
          setLoading(false);
        }
      };
      setLoading(true);
      resetPasswordAsync();
    },
    [token]
  );

  return { loading, showSuccess, closeMessageBox, submitConfirmation };
}
