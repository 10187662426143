import * as React from "react";
import styled from "styled-components";

const LoadingScreen = () => (
  <Overlay>
    <div className="loader">
      <div className="inner one"></div>
      <div className="inner two"></div>
      <div className="inner three"></div>
    </div>
  </Overlay>
);

export default LoadingScreen;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  background-color: rgba(25, 25, 25, 0.2);

  .loader {
    position: absolute;
    top: calc(50% - 60px);
    left: calc(50% - 60px);
    width: 110px;
    height: 110px;
    border-radius: 50%;
    perspective: 800px;
  }

  .inner {
    position: absolute;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  .inner.one {
    left: 0%;
    top: 0%;
    animation: rotate-one 0.9s linear infinite;
    border-bottom: 4px solid #296d98;
  }

  .inner.two {
    right: 0%;
    top: 0%;
    animation: rotate-two 0.9s linear infinite;
    border-right: 4px solid #1c4966;
  }

  .inner.three {
    right: 0%;
    bottom: 0%;
    animation: rotate-three 0.9s linear infinite;
    border-top: 4px solid #0e2433;
  }

  @keyframes rotate-one {
    0% {
      transform: rotateX(50deg) rotateY(15deg) rotateZ(0deg);
    }
    100% {
      transform: rotateX(50deg) rotateY(15deg) rotateZ(360deg);
    }
  }

  @keyframes rotate-two {
    0% {
      transform: rotateX(-40deg) rotateY(35deg) rotateZ(0deg);
    }
    100% {
      transform: rotateX(-40deg) rotateY(35deg) rotateZ(360deg);
    }
  }

  @keyframes rotate-three {
    0% {
      transform: rotateX(35deg) rotateY(50deg) rotateZ(0deg);
    }
    100% {
      transform: rotateX(35deg) rotateY(50deg) rotateZ(360deg);
    }
  }
`;
