import { Form } from "antd";
import { FormItem } from "components/common/Forms/FormItem";
import { Password } from "components/common/Forms/Input";
import { Button } from "components/common/Forms/Button";
import classes from "../styles.module.css";
import validationMessages from "utils/ValidationMessages";
import { useHooks } from "./hooks";
import { checkPassword } from "utils/passwords";
import { useParams } from "react-router";
import { MessageBox } from "../MessageBox";
import { Panel } from "../Panel";

function ConfirmPasswordReset() {
  const { id } = useParams<{ id: string }>();
  const { loading, showSuccess, closeMessageBox, submitConfirmation } = useHooks(id);

  return (
    <Panel>
      <Form
        onFinish={submitConfirmation}
        className={classes.form}
        name="resetPassword"
        layout={"vertical"}
        validateMessages={validationMessages}>
        <FormItem className="mb1 mt2">
          <h1 className={classes.header}>Nästan där</h1>
          <h3 className={classes.header}>Nu kan du ange ditt önskade lösenord</h3>
        </FormItem>

        <FormItem
          name="password"
          className={classes.formItem}
          rules={[
            {
              validator: async (_, value) => {
                const result = await checkPassword(value);

                if (result?.length) {
                  throw result;
                }
              },
            },
          ]}>
          <Password disabled={loading} placeholder="* Lösenord" />
        </FormItem>

        <div className={classes.buttonWrapper}>
          <Button type="primary" htmlType="submit" disabled={loading} size={"large"}>
            Återställ
          </Button>
        </div>

        <MessageBox onCancel={closeMessageBox} visible={showSuccess}>
          <h3 className="mt1">Lösenordet ändrat</h3>
          <h4>Du kommer nu hänvisas till loginsidan där du kan logga in med ditt nya lösenord</h4>
          <br />
        </MessageBox>
      </Form>
    </Panel>
  );
}

export default ConfirmPasswordReset;
