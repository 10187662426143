import React from "react";
import { Link } from "react-router-dom";
import MainLayout from "../../layout/MainLayout";
import { Button } from "antd";
import OfferTable from "../../tables/OfferTable";

const OfferList = () => (
  <MainLayout>
    <div className="pageWrapper">
      <div className="headerWrapper">
        <h1>Offerter</h1>
        <Button className="headerButton">
          <Link to={`/new/offer`}>Ny offert</Link>
        </Button>
      </div>
      <OfferTable />
    </div>
  </MainLayout>
);

export default OfferList;
