import { decodeCurrentUser } from "../utils/tokenHelpers";
import { AuthActionType } from "./reducer";
import { dateInPast } from "../utils/dateHelpers";

interface LoginRequest {
  tenant: string;
  email: string;
  password: string;
}

export async function loginUser(dispatch: React.Dispatch<AuthActionType>, loginPayload: LoginRequest) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(loginPayload),
  };

  try {
    dispatch({ type: "REQUEST_LOGIN" });
    const response = await fetch(`/Token/sign-in`, requestOptions);
    const data = await response.json();
    if (data && response.status === 200) {
      const user = decodeCurrentUser(data.token);
      dispatch({
        type: "LOGIN_SUCCESS",
        payload: {
          user: user,
          token: data.token,
          refreshToken: data.refreshToken,
        },
      });
      localStorage.setItem("token", data.token);
      localStorage.setItem("refresh_token", data.refreshToken);
      return data;
    }

    dispatch({ type: "LOGIN_ERROR", error: "Fel e-post och/eller lösenord" });
    return;
  } catch (error) {
    dispatch({ type: "LOGIN_ERROR", error: "Fel e-post och/eller lösenord" });
  }
}

export async function logoutUser(dispatch: React.Dispatch<AuthActionType>) {
  dispatch({ type: "LOGOUT" });
  localStorage.clear();
}

export async function refreshTokenRequest(token: string, refreshToken: string) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ token: token, refreshToken: refreshToken }),
  };
  const response = await fetch(`/Token/refresh`, requestOptions);
  const data = await response.json();
  if (data && response.status === 200) {
    return data;
  }
}

export const isAuthenticated = (token?: string) => {
  if (!token) return false;
  const user = decodeCurrentUser(token);
  const expired = dateInPast(+user.expiresUnix * 1000);
  return !expired;
};
