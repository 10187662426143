import { OfferType } from "../contracts/offer";

export const changeOfferBudgetLabel = (offerType: OfferType) => {
  switch (offerType) {
    case OfferType.Ongoing:
      return "Total årlig budget";

    case OfferType.Scheduled:
      return "Total budget för angiven tidsperiod";

    case OfferType.Individual:
      return "Budget per objekt";

    case OfferType.OneTime:
      return "Total kostnad";
    default:
      return "Total budget för angiven tidsperiod";
  }
};
